"use client";

import { Box, Button, Divider, Image, Stack, Typography } from "components";
import { withContent } from "contexts/content";

function HomeExplore({ redirect, website }) {
  return (
    <Box id="home-explore" className="BupHome-explore" component="section">
      {/* <Typography variant="h2">{website.get("home.explore.title")}</Typography> */}
      <Stack>
        {website.get("home.explore.cards")?.map((card, i) => (
          <Box className="BupExplore-card" key={i}>
            <Image
              src={`ia/explore-${i + 1}.png`}
              alt={`group-activity-${i + 1}`}
              height={270}
              width={480}
            />
            <Typography variant="h3">{card.subtitle}</Typography>
            <Typography variant="p">{card.description}</Typography>
            <Button
              id={`BH-explore-${i + 1}`}
              className={`BupButton-wide BupButton-${
                i === 0 ? "light" : "dark"
              }`}
              onClick={redirect.register}
              size="large"
            >
              {website.get("home.explore.buttonText")}
            </Button>
          </Box>
        ))}
      </Stack>
      <Divider />
    </Box>
  );
}

export default withContent(HomeExplore);
