"use client";

import { helper, openUrl } from "utils";
import { APP } from "data/consts";

function getDefaultParams(url) {
  if (url.endsWith("basicSignup")) {
    return { source: "WEB" };
  } else return {};
}

function useRedirect() {
  const push = (url, data) => {
    const { params = {}, target = "_self" } = data || {};
    const query = helper.getFromStorage("bup-query") || {};
    const searchParams = Object.assign(
      {},
      getDefaultParams(url),
      query,
      params
    );

    helper.removeFromStorage("bup-query");
    openUrl(url + helper.queryToString(searchParams), "_self");
  };

  return {
    calendar: () => openUrl(process.env.BUSINESS_CALENDAR),
    event: (eid, data) => push(APP.networkUrl + "/app/event/" + eid, data),
    login: (data) => push(APP.networkUrl, data),
    push,
    register: (data) => push(APP.networkUrl + "/basicSignup", data),
  };
}

export default useRedirect;
